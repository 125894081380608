












import { SpotWidgetDataMixin } from '@/features/core/components/spot-widget-control/data-mixin';
import { Component, Mixins } from 'vue-property-decorator';
import bgImage from './electricity-meter-analog-lg.svg';

@Component
export default class ElectricityMeterAnalogDevicePanel extends Mixins(SpotWidgetDataMixin) {
  private backgroundImage = bgImage;
}
